<template>
    <v-container class="d-flex align-center justify-center" style="height: 90%">
        <v-row class="d-flex align-center justify-center">
            <v-col cols="12" md="8">
                <base-material-card>
                    <template v-slot:heading>
                        <div class="display-2 font-weight-light">
                            Iniciar Sesión
                        </div>

                    </template>

                    <v-form v-model="valid">
                        <v-container class="py-0">
                            <v-row>
                                <v-col cols="12" class="mt-6">
                                    <v-text-field label="Usuario" v-model="email" class="purple-input" type="text"
                                        :rules="[rules.required, rules.nameSafe]" />
                                </v-col>

                                <v-col cols="12">
                                    <v-text-field v-model="password" :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                                        :rules="[rules.required, rules.min, rules.sqlSafe]"
                                        :type="show1 ? 'text' : 'password'" name="input-10-1" label="Contraseña"
                                        hint="Al menos 8 caracteres" counter @click:append="show1 = !show1"></v-text-field>
                                </v-col>

                                <v-col cols="12" class="text-right">
                                    <v-btn color="success" :disabled="submitDisabled" class="mr-0" @click="submitForm">
                                        Identificarse
                                    </v-btn>

                                </v-col>
                                
                            </v-row>
                        </v-container>
                    </v-form>
                </base-material-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script lang="js">
import router from '../../router.js'

import axios from "axios"
const API = axios.create({
    baseURL: "https://main-api.apptobus.com/",
})

export default {
    data () {
        return {
            show1: false,
            valid: false,   
            email: '',
            password: '',
            rules: {
                required: value => !!value || 'Campo necesario',
                min: v => v.length >= 7 || 'Min 7 caracteres',
                emailMatch: () => (`The email and password you entered don't match`),
                sqlSafe: v => /^[a-zA-Z0-9_@#',.()\[\]\s-]+$/.test(v) || 'No se permiten caracteres especiales en este campo',
                nameSafe: v => /^[a-zA-Z0-9.-]+@[a-zA-Z0-9.-]+\.[a-z]+$/i.test(v) || 'Solo se permiten emails'
            }
        }
    },
    computed: {
        submitDisabled () {
            return !this.valid; // devuelve `true` si el formulario no es válido
        },
    },
    methods: {
        async submitForm () {
            const sendData = { email: this.email, password: this.password }
            await API.post('api/auth/login', sendData)
                .then(async function (response) {
                    // manejar respuesta exitosa
                    const token = response.data.token
                    const operator = response.data.operator
                    const operator_id = response.data.operator_id
                    const email = response.data.email
                    localStorage.setItem('token', token)
                    localStorage.setItem('operator', operator)
                    localStorage.setItem('operator_id', operator_id)
                    localStorage.setItem('email', email)
                    router.push('/')
                })
                .catch(function (error) {
                    // manejar error
                    console.log(error);
                })
        },
    },
}
</script>